<template>
  <div class="container answers">
    <div class="action-btns">
      <div v-if="createNew === '1'">
        <div>
          <el-button style="width:150px" type="success" @click="createAnswers"
            ><i class="fa fa-save"></i> 創 建</el-button
          >
        </div>
      </div>
      <div v-else>
        <div v-show="cannotEdit">
          <el-button type="success" @click="updateScore">
            重新批改所有试卷
          </el-button>
          <el-button class="testIdSave" @click="cannotEdit = false" type="info"
            ><i class="fa fa-edit"></i> 編 輯</el-button
          >
        </div>
        <div v-show="!cannotEdit">
          <div>
            <el-button style="width:150px" type="success" @click="updateAnswers"
              ><i class="fa fa-save"></i> 保 存</el-button
            >
          </div>
          <div>
            <el-button
              style="width:150px;margin-top:10px;color: red"
              class="testIdSave"
              @click="cannotEdit = true"
              ><i class="fa fa-times"></i> 取 消</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Browse' }">
            Browse
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ sat_info["full_name"] }} ({{ sat_info["title"] }})
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h2>
      <span v-if="createNew === '1'">
        Add New Test
      </span>
      <span v-else>
        {{ sat_info["full_name"] }} ({{ sat_info["title"] }})
      </span>
    </h2>
    <ExaminationBaseInfo :info="sat_info" :cannotEdit="cannotEdit" />
    <hr />
    <h4 class="title-text">
      TEST PASSAGE AND QUESTION
    </h4>
    <ExaminationSectionTable :testTypeId="$route.query.exam_id" />
    <div class="">
      <hr />
      <div class="title">
        <h4 class="title-text">
          TEST ANSWER
        </h4>
      </div>
      <div v-show="!cannotEdit">
        <div style="margin-bottom:20px">
          <el-alert type="warning" :closable="false" show-icon>
            <ul style="margin-bottom:0">
              <li>數字題用括號 <b>(1/2,0.5)</b> ;</li>
              <li>單選題中有多個正確答案用括號 <b>(A,B)</b> ;</li>
              <li>括號中的答案用英文狀態下的逗號 <b>,</b> 隔開;</li>
              <li>全部都算對用 <b>(*)</b> 表示。</li>
            </ul>
          </el-alert>
        </div>
        <div class="test_input">
          <div
            class="row input-line"
            v-for="(answer, index) in test_answers_detail"
            :key="index"
            v-show="index !== 'essay'"
          >
            <div class="col-md-1">
              <span class="input-title">
                <b v-html="topic_array[index]"></b>
              </span>
            </div>
            <div class="col-md-11">
              <div class="input-group">
                <input
                  class="form-control"
                  rows="2"
                  v-model="test_answers_detail[index]"
                  @change="setAllAnswer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 class="title-text">
        Preview:
      </h5>
      <div class="row">
        <div class="col-md-6" style="padding-right:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td
                  v-for="(section, index) in content"
                  :key="index"
                  v-show="index != 'essay'"
                >
                  <b v-html="topic_array[index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in content[max_topic]"
                :key="index"
                v-show="index <= 25"
              >
                <td>
                  <b>{{ index + 1 }}</b>
                </td>
                <td
                  v-for="(title, index1) in content"
                  :key="index1"
                  v-show="index1 != 'essay'"
                >
                  <input
                    v-if="title[index] !== undefined"
                    class="form-control"
                    :value="title[index].answers"
                    :disabled="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-6" style="padding-left:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td
                  v-for="(section, index) in content"
                  :key="index"
                  v-show="index != 'essay'"
                >
                  <b v-html="topic_array[index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in content[max_topic]"
                v-show="index > 25"
                :key="index"
              >
                <td>
                  <b>{{ index + 1 }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in content"
                  :key="index1"
                  v-show="index1 != 'essay'"
                >
                  <input
                    v-if="title[index] !== undefined"
                    class="form-control"
                    name=""
                    :value="title[index].answers"
                    placeholder=""
                    :disabled="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="">
      <hr />
      <h4 class="title-text">
        SCORING TABLE
      </h4>
    </div>
    <div>
      <h4 class="title-text">
        Raw Score Conversion Table 1: Section and Test Scores
      </h4>
      <div style="margin-bottom:20px" v-show="!cannotEdit">
        <el-alert type="warning" :closable="false" show-icon>
          <ul style="margin-bottom:0">
            <li>Reading Test Score: (<b>100 ~ 400</b>) ;</li>
            <li>Writing Test Score: (<b>100 ~ 400</b>) ;</li>
            <li>Math Test Score: (<b>100 ~ 800</b>).</li>
          </ul>
        </el-alert>
      </div>
      <div class="row">
        <div class="col-sm-6" style="padding-right:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td v-for="(title, index) in score_table" :key="index">
                  <b v-html="topic_array[index] + ' Test Score'"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in score_table[max_score_table]"
                v-show="index <= 29"
                :key="index"
              >
                <td>
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in score_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    name=""
                    v-show="title[index] !== undefined"
                    v-model.number="title[index]"
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6" style="padding-left:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td v-for="(title, index) in score_table" :key="index">
                  <b v-html="topic_array[index] + ' Test Score'"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(answer, index) in score_table[max_score_table]"
                v-show="index > 29"
                :key="index"
              >
                <td>
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in score_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    name=""
                    v-show="title[index] !== undefined"
                    v-model.number="title[index]"
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="">
      <h4 class="title-text">Subscores</h4>
      <table
        id="testresult_answer"
        class="table table-condensed fixed-column"
        style="text-align: center;"
      >
        <thead>
          <tr>
            <td><b>Tags</b></td>
            <td
              v-for="(topic, arr_tag) in sub_topic_array"
              :key="arr_tag"
              v-show="
                arr_tag === 'is_coe' ||
                  arr_tag === 'is_wic' ||
                  arr_tag === 'is_eoi' ||
                  arr_tag === 'is_sec'
              "
            >
              <b v-html="topic"></b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(section, key) in type_content"
            :key="key"
            v-show="key != 'essay'"
          >
            <td><b v-html="topic_array[key]"></b></td>
            <td
              style="text-align: left;"
              v-for="(topic, arr_tag) in sub_topic_array"
              :key="arr_tag"
              v-show="
                arr_tag === 'is_coe' ||
                  arr_tag === 'is_wic' ||
                  arr_tag === 'is_eoi' ||
                  arr_tag === 'is_sec'
              "
            >
              <span
                v-for="(list, index) in section"
                :class="{ active: list[arr_tag] == 1, list_btn: true }"
                @click="setTag(arr_tag, list)"
                :key="index"
                v-show="(list[arr_tag] == 1 && cannotEdit) || !cannotEdit"
              >
                {{ index + 1 }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        id="testresult_answer"
        class="table table-condensed fixed-column"
        style="text-align: center;"
      >
        <thead>
          <tr>
            <td><b>Tags</b></td>
            <td
              v-for="(topic, arr_tag) in sub_topic_array"
              :key="arr_tag"
              v-show="
                arr_tag === 'is_hoa' ||
                  arr_tag === 'is_psada' ||
                  arr_tag === 'is_ptam'
              "
            >
              <b v-html="topic"></b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(section, key) in type_content"
            :key="key"
            v-show="key != 'essay'"
          >
            <td><b v-html="topic_array[key]"></b></td>
            <td
              style="text-align: left;"
              v-for="(topic, arr_tag) in sub_topic_array"
              :key="arr_tag"
              v-show="
                arr_tag === 'is_hoa' ||
                  arr_tag === 'is_psada' ||
                  arr_tag === 'is_ptam'
              "
            >
              <span
                v-for="(list, index) in section"
                :class="{ active: list[arr_tag] == 1, list_btn: true }"
                @click="setTag(arr_tag, list)"
                :key="index"
                v-show="(list[arr_tag] == 1 && cannotEdit) || !cannotEdit"
              >
                {{ index + 1 }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="">
      <h4 class="title-text">
        Raw Score Conversion Table 2: Subscores
      </h4>
      <div style="margin-bottom:20px" v-show="!cannotEdit">
        <el-alert type="warning" :closable="false" show-icon>
          <ul style="margin-bottom:0">
            <li>Section Test Score: (<b>1 ~ 15</b>) ;</li>
          </ul>
        </el-alert>
      </div>
      <table
        id="testresult_answer"
        class="table table-condensed fixed-column"
        style="text-align: center;"
      >
        <thead>
          <tr>
            <td><b>RAW SCORE </b><br />(# OF CORRECT ANSWERS)</td>
            <td v-for="(title, index) in subscores_table" :key="index">
              <b v-html="sub_topic_array['is_' + index]"></b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(num, index) in 25" :key="index">
            <td>
              <b>{{ index }}</b>
            </td>
            <td
              style="background-color: rgb(255, 255, 255);"
              v-for="(title, index1) in subscores_table"
              :key="index1"
            >
              <input
                class="form-control"
                name=""
                v-model="title[index]"
                placeholder=""
                :disabled="cannotEdit"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-divider></el-divider>
    <div class="">
      <h4 class="title-text">Cross-Test Scores</h4>
      <table
        id="testresult_answer"
        class="table table-condensed fixed-column"
        style="text-align: center;"
      >
        <thead>
          <tr>
            <td><b>Tags</b></td>
            <td v-for="(topic, arr_tag) in cross_topic_array" :key="arr_tag">
              <b v-html="topic"></b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(section, key) in type_content"
            :key="key"
            v-show="key != 'essay'"
          >
            <td><b v-html="topic_array[key]"></b></td>
            <td
              style="text-align: left;"
              v-for="(topic, arr_tag) in cross_topic_array"
              :key="arr_tag"
            >
              <span
                v-for="(list, index) in section"
                :class="{ active: list[arr_tag] == 1, list_btn: true }"
                @click="setTag(arr_tag, list)"
                :key="index"
                v-show="(list[arr_tag] == 1 && cannotEdit) || !cannotEdit"
              >
                {{ index + 1 }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="">
      <h4 class="title-text">
        Raw Score Conversion Table 3: Cross-Test Scores
      </h4>
      <div style="margin-bottom:20px" v-show="!cannotEdit">
        <el-alert type="warning" :closable="false" show-icon>
          <ul style="margin-bottom:0">
            <li>Section Test Score: (<b>10 ~ 40</b>) ;</li>
          </ul>
        </el-alert>
      </div>
      <div class="row">
        <div class="col-sm-6" style="padding-right:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td
                  v-for="(title, index) in cross_test_scores_table"
                  :key="index"
                >
                  <b v-html="cross_topic_array['is_' + index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(num, index) in 36" :key="index" v-show="index <= 17">
                <td>
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in cross_test_scores_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    name=""
                    v-model="title[index]"
                    placeholder=""
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm-6" style="padding-left:0">
          <table
            id="testresult_answer"
            class="table table-condensed fixed-column"
            style="text-align: center;"
          >
            <thead>
              <tr>
                <td><b>#</b></td>
                <td
                  v-for="(title, index) in cross_test_scores_table"
                  :key="index"
                >
                  <b v-html="cross_topic_array['is_' + index]"></b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(num, index) in 36" :key="index" v-show="index > 17">
                <td>
                  <b>{{ index }}</b>
                </td>
                <td
                  style="background-color: rgb(255, 255, 255);"
                  v-for="(title, index1) in cross_test_scores_table"
                  :key="index1"
                >
                  <input
                    class="form-control"
                    name=""
                    v-model="title[index]"
                    placeholder=""
                    :disabled="cannotEdit"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import satApi from "@/apis/sat.js";
import Breadcrumb from "@/components/Breadcrumb";
import ExaminationBaseInfo from "@/views/satList/components/ExaminationBaseInfo.vue";
import ExaminationSectionTable from "@/views/satList/components/ExaminationSectionTable.vue";

export default {
  metaInfo() {
    return {
      title: "Test Answers - " + this.CompanyName
    };
  },
  components: {
    Breadcrumb,
    ExaminationBaseInfo,
    ExaminationSectionTable
  },

  mixins: [],

  props: [],
  data() {
    return {
      sat_info: {
        full_name: "",
        id: 0,
        title: "",
        url: {
          test: ""
        }
      },
      content: {
        reading: [],
        writing: [],
        math: [],
        "math calculator": []
      },
      type_content: {
        reading: [],
        writing: [],
        math: [],
        "math calculator": []
      },
      test: "barronsat0",
      max_topic: "reading",
      max_score_table: "math",
      max_sub_section_name: "eoi",
      max_cross_section_name: "aih",
      score_table: {
        reading: [],
        writing: [],
        math: []
      },
      subscores_table: {
        coe: Array.from(new Array(19)).map(() => ""),
        wic: Array.from(new Array(19)).map(() => ""),
        eoi: Array.from(new Array(25)).map(() => ""),
        sec: Array.from(new Array(21)).map(() => ""),
        hoa: Array.from(new Array(20)).map(() => ""),
        psada: Array.from(new Array(18)).map(() => ""),
        ptam: Array.from(new Array(17)).map(() => "")
      },
      cross_test_scores_table: {
        aih: Array.from(new Array(36)).map(() => ""),
        ais: Array.from(new Array(36)).map(() => "")
      },
      cannotEdit: true,
      topic_array: {
        reading: "Reading",
        writing: "Writing",
        math: "Math",
        "math calculator": "Math <i class='fa fa-calculator'></i>"
      },
      sub_topic_array: {
        is_coe: "Command of Evidence",
        is_wic: "Words in Context",
        is_eoi: "Expression of Ideas",
        is_sec: "Standard English Conventions",
        is_hoa: "Heart of Algebra",
        is_psada: "Problem Solving and Data Analysis",
        is_ptam: "Passport to Advanced Math"
      },
      cross_topic_array: {
        is_aih: "ANALYSIS IN HISTORY",
        is_ais: "ANALYSIS IN SCIENCE"
      },
      load: false,
      sections: ["Reading", "Writing", "Math", "MathC"],
      test_answers_detail: {
        reading: "",
        writing: "",
        math: "",
        "math calculator": ""
      },
      createNew: 0,
      fileLoading: false
    };
  },
  computed: {
    exam_id() {
      let exam_id = this.$route.query.exam_id;
      return exam_id;
    },
    canNotEdit() {
      let canNotEdit = true;
      if (this.$route.query.can_edit == 1 || this.$route.query.create == 1) {
        canNotEdit = false;
      } else {
        canNotEdit = true;
      }
      return canNotEdit;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "document";
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.create) {
      this.createNew = this.$route.query.create;
    } else {
      this.getAnswers();
    }
    this.cannotEdit = this.canNotEdit;
  },

  methods: {
    setAllAnswer() {
      this.setAnswer("reading");
      this.setAnswer("writing");
      this.setAnswer("math");
      this.setAnswer("math calculator");
      this.setMax();
    },
    setMax() {
      let gradeOne_new = [];
      const content = this.content;
      for (let i in content) {
        let obj = {
          key: i,
          data: content[i],
          length: content[i].length
        };
        gradeOne_new.push(obj);
      }
      let max = gradeOne_new.sort(function(a, b) {
        return b.length - a.length;
      })[0];
      this.max_topic = max.key;

      let scoreTable_new = [];
      const scoreTable = this.score_table;
      for (let i in scoreTable) {
        let obj = {
          key: i,
          data: scoreTable[i],
          length: scoreTable[i].length
        };
        scoreTable_new.push(obj);
      }
      let max_score = scoreTable_new.sort(function(a, b) {
        return b.length - a.length;
      })[0];
      this.max_score_table = max_score.key;
    },
    async getAnswers() {
      await Promise.all([
        satApi.getAnswers(this.exam_id),
        satApi.getType(this.exam_id),
        satApi.getTest("SAT", this.exam_id),
        satApi.getScoreConversion(this.exam_id),
        satApi.getSubscoreConversion(this.exam_id),
        satApi.getCrossTestConversion(this.exam_id)
      ]).then(values => {
        const res = values[0];
        const type = values[1];
        const info = values[2];
        const score_conversion = values[3];
        const subscore_conversion = values[4];
        const cross_test_conversion = values[5];
        const sections = ["reading", "writing", "math"];
        sections.forEach(section => {
          if (res[section]) {
            this.content[section] = res[section];
            this.type_content[section] = type.questions[section];
            this.score_table[section] =
              score_conversion.score_conversion[section];
          }
        });
        if (res["math calculator"]) {
          this.content["math calculator"] = res["math calculator"];
          this.type_content["math calculator"] =
            type.questions["math calculator"];
          this.score_table.writing = score_conversion.score_conversion.writing;
        }

        this.sat_info = info;
        const { subscore_conversion: subscoreConversion } = subscore_conversion;
        let workingSubScoreConversion = {};
        if (subscoreConversion !== null) {
          Object.keys(subscoreConversion).forEach(key => {
            workingSubScoreConversion[key] = subscoreConversion[key].map(
              value => value || []
            );
          });
          this.subscores_table = workingSubScoreConversion;
        }
        if (cross_test_conversion.cross_test_conversion !== null) {
          this.cross_test_scores_table =
            cross_test_conversion.cross_test_conversion;
        }
        this.test_answers_detail = this.getTestAnswersDetail();
        this.setMax();
      });
    },
    getTestAnswersDetail() {
      let content = this.content;
      let new_obj = {};

      for (let key in content) {
        new_obj[key] = this.getChoices(content[key]);
      }
      return new_obj;
    },
    async updateScore() {
      let vm = this;
      vm.load = true;
      await satApi.updateAllSATStudents(this.exam_id);
    },
    async updateAnswers() {
      let contentIndex = 1;
      for (let i in this.content) {
        let section = this.content[i];
        let newSection = _.filter(section, function(answser) {
          return answser.answers !== "";
        });
        newSection.forEach(answer => {
          answer.order = contentIndex;
          contentIndex++;
        });
        contentIndex = 1;
        this.content[i] = newSection;
      }
      await Promise.all([
        satApi.updateAnswers(this.exam_id, {
          answers: this.content,
          questions: this.type_content
        }),
        satApi.updateTestInfo("SAT", this.exam_id, {
          title: this.sat_info.title,
          full_name: this.sat_info.full_name,
          url: {
            test: this.sat_info.url !== null ? this.sat_info.url.test : ""
          },
          test_book_ids: this.sat_info.test_book_ids
        }),
        satApi.updateScoreConversion(this.exam_id, {
          score_conversion: this.score_table
        }),
        satApi.updateSubscoreConversion(this.exam_id, {
          subscore_conversion: this.subscores_table
        }),
        satApi.updateCrossTestConversion(this.exam_id, {
          cross_test_conversion: this.cross_test_scores_table
        })
      ]);
      this.reloadAnswer(this.sat_info.id);
      this.cannotEdit = true;
    },
    getChoices(arr) {
      let ans = "";
      arr.forEach(val => {
        let item = val.answers;
        let value = "";
        if (item !== undefined) {
          if (item.length > 2) {
            value = `(${item})`;
          } else {
            if (isNaN(item)) {
              value = item;
            } else {
              value = `(${item})`;
            }
          }
        }
        ans += value;
      });
      return ans;
    },
    setAnswer: function(string) {
      let answer = this.test_answers_detail[string];
      let newArr = answer.split("");
      let i = 0;
      let y = 0;
      let index = 0;
      if (this.createNew === "1") {
        this.content[string] = [];
        this.type_content[string] = [];
        while (i < newArr.length) {
          this.content[string].push({
            answers: "",
            name: string,
            order: 0,
            sat_question_id: 0
          });
          this.type_content[string].push({
            content: "",
            exp: "",
            id: 1,
            is_aih: 0,
            is_ais: 0,
            is_coe: 0,
            is_eoi: 0,
            is_hoa: 0,
            is_psada: 0,
            is_ptam: 0,
            is_sec: 0,
            is_wic: 0,
            name: string,
            order: 0
          });

          if (newArr[i] == "(") {
            y = answer.indexOf(")", i);
            let answer_new = answer.substring(i + 1, y);
            this.content[string][index]["answers"] = answer_new;
            i = y;
          } else {
            y = i;
            this.content[string][index]["answers"] = newArr[i];
          }
          i++;
          index++;
        }
      } else {
        this.content[string].forEach(res => {
          res.answers = "";
        });
        while (i < newArr.length) {
          if (this.content[string][index] == undefined) {
            this.content[string].push({
              answers: "",
              name: string,
              order: 0,
              sat_question_id: 0
            });
            this.type_content[string].push({
              content: "",
              exp: "",
              id: 1,
              is_aih: 0,
              is_ais: 0,
              is_coe: 0,
              is_eoi: 0,
              is_hoa: 0,
              is_psada: 0,
              is_ptam: 0,
              is_sec: 0,
              is_wic: 0,
              name: string,
              order: 0
            });
          }
          if (newArr[i] == "(") {
            y = answer.indexOf(")", i);
            let answer_new = answer.substring(i + 1, y);
            this.content[string][index]["answers"] = answer_new;
            i = y;
          } else {
            y = i;
            this.content[string][index]["answers"] = newArr[i];
          }
          i++;
          index++;
        }
        let newSection = _.filter(this.content[string], function(answser) {
          return (
            answser.sat_question_id > 0 ||
            (answser.answers !== "" && answser.sat_question_id === 0)
          );
        });
        this.content[string] = newSection;
      }
      //評分表的长度
      if (string === "reading" || string === "writing") {
        let j = 0;
        let arr = [0];
        while (j < this.content[string].length) {
          if (this.content[string][j].answers !== "") {
            if (this.score_table[string][j] === undefined) {
              arr.push(0);
            } else {
              arr.push(this.score_table[string][j]);
            }
          }
          j++;
        }
        this.score_table[string] = arr;
      } else {
        let j = 0;
        let arr = [0];
        while (j < this.content["math"].length) {
          if (this.content["math"][j].answers !== "") {
            if (this.score_table["math"][j] === undefined) {
              arr.push(0);
            } else {
              arr.push(this.score_table["math"][j]);
            }
          }
          j++;
        }
        let length = arr.length;
        let i = 0;
        while (i < this.content["math calculator"].length) {
          if (this.content["math calculator"][i].answers !== "") {
            // arr.push(this.score_table[string][j]);
            if (this.score_table["math"][length + i] === undefined) {
              // this.score_table["math"][j] = 0;
              arr.push(0);
            } else {
              arr.push(this.score_table["math"][length + i]);
            }
          }
          i++;
        }
        this.score_table["math"] = arr;
      }
    },
    editTestId() {},
    classSelect(str, question) {
      if (question[str] == 1) {
        return "active list_btn";
      } else {
        return "list_btn";
      }
    },
    setTag(str, question) {
      if (!this.cannotEdit) {
        if (question[str] === 1) {
          question[str] = 0;
        } else if (question[str] === 0) {
          question[str] = 1;
        }
      }
    },
    async createAnswers() {
      const res = await satApi.createAnswers({
        info: this.sat_info,
        answers: this.content,
        questions: this.type_content,
        score_conversion: this.score_table,
        subscore_conversion: this.subscores_table,
        cross_test_conversion: this.cross_test_scores_table
      });
      this.reloadAnswer(res.sat_id);
    },
    reloadAnswer(sat_id) {
      this.$router.replace({
        path: "/sat/answers",
        query: {
          exam_id: sat_id
        }
      });
    },
    handleAvatarSuccess(res) {
      this.sat_info.url = { test: res.document.url };
      this.fileLoading = false;
    },
    beforeAvatarUpload() {
      let canUpload = true;
      this.fileLoading = true;
      return canUpload;
    }
  }
};
</script>

<style scoped>
.answers {
  padding: 20px 0;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
}
hr {
  height: 4px;
  background-color: var(--themeColor);
}
.action-btns {
  margin-bottom: 15px;
}
h4.title-text {
  color: var(--themeColor);
  margin-right: 10px;
  line-height: 44px;
  height: 44px;
  margin-bottom: 0;
}
.title {
  display: flex;
  margin-bottom: 10px;
}
.title .title-div {
  margin-left: 20px;
}
.title-input.form-control {
  text-align: left;
}
.input-line {
  margin-bottom: 20px;
}
.input-title {
  line-height: 44px;
}

ol.breadcrumb {
  margin-bottom: 0px;
}
.panel-heading {
  padding: 0 15px;
}
.panel-title {
  font-size: 26px;
  color: var(--themeColor);
}
.panel-body {
  padding: 15px;
}
select#test_id {
  height: 25px;
  margin-bottom: 0px;
}
.test_type {
  background: #ddd;
  padding-left: 20px;
  cursor: pointer;
}
.test_type:hover {
  background: #cacaca;
}
.sign {
  min-width: 25px;
}
.test_list {
  background: #fafafa;
  padding-left: 30px;
  display: none;
}
.panel {
  margin-bottom: 50px;
}

#testresult_answer,
#testresult_table {
  text-align: center;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -khtml-border-radius: 2px;
  -webkit-border-radius: 2px;
  border: var(--themeColor) 1px solid;
}

#testresult_answer td,
#testresult_table td {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: var(--themeColor) 1px solid;
  border-right: 3px solid var(--themeColor);
}

#testresult_answer thead td,
#testresult_table thead td {
  padding: 10px 0;
}
.form-control {
  text-align: center;
  font-weight: 700;
  /* border: 1px solid #fff; */
  box-shadow: none;
  font-size: 20px;
}
.form-control:hover {
  border: 1px solid #ccc;
}
.test_id .form-control {
  border: 1px solid #ccc;
}
#add_button {
  border: 1px solid #fff;
  background: #fe4902;
  display: block;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 70px;
  right: 17px;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
}
.btn_active {
  background-color: white;
  color: var(--themeColor);
}
h3 .form-control {
  display: inline-block;
  width: 50%;
  text-align: left;
}
.list_btn {
  width: 34px;
  background-color: white;
  color: black;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.list_btn.active {
  background-color: var(--themeColor);
  color: white;
}
.table .form-control {
  /* height: 25px; */
  padding: 3px 12px;
}
h2 .testIdSave {
  display: inline-block;
  cursor: pointer;
  color: var(--themeColor);
  /*font-size: 14px;*/
  line-height: 34px;
  height: 34px;
  cursor: pointer;
}
.test_input .form-control {
  border: 1px solid #ccc;
  text-align: left;
}
.comments {
  text-align: left;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: normal;
}
.load {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8001;
  background: black;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
.fullscreen-hint {
  font-size: 24px;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 8002;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
</style>
