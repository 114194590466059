<template>
  <div>
    <!-- TEST File -->
    <div style="padding-bottom:0">
      <hr />
      <div style="padding-bottom:0">
        <div class="title">
          <h4 class="title-text">
            TEST INFORMATION
          </h4>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Test</b>
                </span>
              </div>
              <div class="col-md-11">
                <input
                  class="form-control title-input"
                  type="text"
                  v-model="info['full_name']"
                  :disabled="cannotEdit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Test ID</b>
                </span>
              </div>
              <div class="col-md-11">
                <input
                  class="form-control title-input"
                  type="text"
                  v-model="info['title']"
                  :disabled="cannotEdit"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="title-text">
          <div class="test_input">
            <div class="row input-line">
              <div class="col-md-1">
                <span class="input-title">
                  <b>Book</b>
                </span>
              </div>
              <div class="col-md-11">
                <el-select
                  v-model="info.test_book_ids"
                  multiple
                  style="width:100%"
                  :disabled="cannotEdit"
                >
                  <el-option
                    v-for="book in books"
                    :key="book.id"
                    :label="book.title"
                    :value="book.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import satApi from "@/apis/sat.js";

export default {
  components: {},

  mixins: [],

  props: ["info", "cannotEdit"],
  data() {
    return {
      books: []
    };
  },
  mounted() {
    this.getSatBooks();
  },

  methods: {
    async getSatBooks() {
      const res = await satApi.getSatBooks();
      this.books = res.test_books;
    }
  }
};
</script>

<style scoped>
.answers {
  padding: 20px 0;
}
.action-btns {
  z-index: 9999999;
  position: fixed;
  top: 120px;
  right: 0px;
}
hr {
  height: 2px;
  background-color: var(--themeColor);
}
.action-btns {
  margin-bottom: 15px;
}
h4.title-text {
  color: var(--themeColor);
  margin-right: 10px;
  line-height: 44px;
  height: 44px;
  margin-bottom: 0;
}
.input-line {
  margin-bottom: 20px;
}
.title {
  display: flex;
  margin-bottom: 10px;
}
</style>
